<template>
  <div
    v-show="index === 0 ? true : isMounted"
    class="slide-expand-group"
  >
    <div
      ref="rowEl"
      class="slide-expand-row"
    >
      <slot
        :items="lista"
      ></slot>
    </div>
    <div
      v-show="listb.length"
      class="slide-expand-row"
    >
      <slot
        :items="listb"
      ></slot>
    </div>
  </div>
</template>

<script>

import { defineComponent, ref, onMounted } from 'vue'

export default defineComponent({
  name: 'SlideExpandGroup',
  props: {
    lista: {
      type: Array,
      default: () => [],
    },
    listb: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    setRowHeight: {
      type: Function,
      default: function () {},
    },
  },
  setup (props) {

    const rowEl = ref(null)
    const isMounted = ref(false)

    onMounted(() => {
      isMounted.value = true

      const fn = () => {
        const $row = rowEl.value
        const h = Number.parseInt($row.clientHeight)
        if (h > 10) {
          props.setRowHeight(h)
        } else {
          setTimeout(fn, 100)
        }
      }
      setTimeout(fn, 0)
    })

    return {
      isMounted,
      rowEl,
    }
  }
})
</script>

<style lang="less" scoped>
.slide-expand-row {
  display: flex;

  & + & {
    margin-top: 6px;
  }
}
</style>
