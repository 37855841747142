/**
 * 处理点击图文跳转真实分类页
 */

import { getCurrentInstance } from 'vue'
import { markPoint } from 'public/src/services/mark/index.js'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { handlePicTopNavParams, handlePicTopNavQueryChain } from 'public/src/pages/components/FilterBar/PicTopNav/utils/index.js'

export default function useToRealPage ({ list, extraParams, catInfo }) {
  const vm = getCurrentInstance()
  const route = vm.proxy.$route
  const router = vm.proxy.$router

  if (!vm || !route || !router) return () => {}
  const onChange = ({ tabIndex = 0, navIndex }) => {
    const navItem = list.value?.[tabIndex]?.navs?.[navIndex]
    if (!navItem) return
    const { nav_id, nav_name, nav_type, rec_type, goodsId = '' } = navItem
    const { src_identifier, src_module, src_tab_page_id, entranceType, nav_entrance_type, srctype, nav_src_type } = route.query
    let from_page_cate_id = '' // 上个页面的分类id
    let to_page_cate_id = '' // 当前页面的分类id,下个页面使用
    const { pageName, entity_id, select_id } = catInfo.value
    if (['page_real_class', 'page_select_class'].includes(pageName)) {
      to_page_cate_id = entity_id || select_id || '-'
      from_page_cate_id = catInfo.value.from_page_cate_id || '-'
    }
    const query = {
      adp: goodsId,
      src_identifier,
      src_module,
      src_tab_page_id,
      nav_query_adp: goodsId,
      nav_query_id: nav_id,
      nav_query_type: nav_type,
      nav_query_pos: navIndex + 1,
      nav_query_name: nav_name,
      nav_query_rec_type: rec_type,
      nav_jump_type: 1,
      source: 'imgNavQuery',
      source_status: 1,
      nav_src_type: srctype || nav_src_type,
      nav_entrance_type: entranceType || nav_entrance_type,
      from_page_cate_id: to_page_cate_id,
      ...handlePicTopNavQueryChain(),
    }
    const params = handlePicTopNavParams(unrefs({ list, tabIndex, navIndex, noClearParams: true, extraParams: {
      pic_tab_jump_type: 1,
      from_page_cate_id,
      ...extraParams,
    } }))
    markPoint('toNextPageClick', 'public')
    const id = String(nav_id).replace(/^3000/, '')

    analysis.clickPicTopNav(params)
    router.push({
      query,
      path: `${encodeURIComponent(nav_name)}-c-${id}.html`,
    })
  }

  return onChange
}
