export function getPicTopNavAnalysisData ({ tabItem, tabIndex = 0, navItem, navIndex = 0, catInfo, isUseSearch }) {
  const { tabId, tabType, is_blank } = tabItem
  const { goodsId, nav_id, nav_type, rec_type, nav_name } = navItem
  const { pageName, from_page_cate_id } = catInfo.value
  const commonData = {
    pageName,
    from_page_cate_id: (['page_real_class', 'page_select_class'].includes(pageName) && !isUseSearch)
      ? (from_page_cate_id || '-')
      : '',
  }
  // 曝光
  const exposeData = {
    id: '2-17-17',
    code: 'picTopNav',
    prefix: 'picTopNav',
    delayReady: 1500,
    data: {
      tabId,
      tabType,
      tabIndex,
      nav_type,
      nav_name,
      nav_id,
      goodsId,
      rec_type,
      is_blank,
      index: navIndex,
      isUseSearch,
      ...commonData
    }
  }
  // 点击
  const tapData = {
    id: '2-17-19',
    data: {
      pic_nav_adp: goodsId,
      pic_nav_id: nav_id,
      pic_nav_type: nav_type,
      pic_nav_pos: navIndex,
      pic_rec_type: rec_type,
      pic_tab_id: tabId,
      pic_tab_type: tabType,
      pic_tab_pos: tabIndex + 1,
      isUseSearch,
      ...commonData,
    }
  }

  return {
    expose: exposeData,
    tap: tapData
  }
}

export function getTabAnalysisData (tabItem, index) {
  const { tabId, tabType } = tabItem
  const data = { tabId, tabType, index }
  const tapData = { id: '2-17-22', data }
  const exposeData = { id: '2-17-21', code: 'picTopNavTab', delayReady: 1500, data }

  return {
    tap: tapData,
    expose: exposeData
  }
}
