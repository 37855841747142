<template>
  <div 
    class="nav-bar__price"
    role="tab"
    :aria-label="label"
    @click="onClick"
  >
    <span
      :class="{
        'nav-bar__label': true,
        'nav-bar__label-active': isActive,
      }"
    >
      {{ label }}
    </span>

    <span class="nav-bar__icon">
      <span 
        class="nav-bar__icon-asc" 
        :style="{ color: value == ascValue ? '#222' : '#959595' }"
      >
        <Icon
          size="12px"
          name="sui_icon_more_s_triangle_up_12px"
        />
      </span>

      <span 
        class="nav-bar__icon-desc"
        :style="{ color: value == descValue ? '#222' : '#959595' }"
      >
        <Icon
          size="12px"
          name="sui_icon_more_s_triangle_down_12px"
        />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'NavBarPrice',
}
</script>

<script setup>
import { watch, ref, onMounted, inject, } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { emitCloseDropdown } from 'public/src/pages/components/FilterBar/eventCenter/index.js'
import { getClickType, analysisClickSort } from 'public/src/pages/components/FilterBar/NavBar/utils/analysis.js'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'

const emits = defineEmits(['click', 'change'])
const props = defineProps({
  label: { type: String, default: '' },
  value: { type: [String, Number], default: '' },
  ascValue: { type: [String, Number], default: '' },
  descValue: { type: [String, Number], default: '' },
})

const sortMap = inject('sortMap', [])
const topTwoList = inject('topTwoList', [])
const isConfigAutoSticky = inject('isConfigAutoSticky', false)
// const isActive = computed(() => props.value == props.ascValue || props.value == props.descValue)
const isActive = ref(false)

const setActive = () => {
  isActive.value = props.value == props.ascValue || props.value == props.descValue
}
watch(() => props.value, () => {
  setActive()
})

onMounted(() => {
  setActive()
})

const toStickyByClick = () => emits('toStickyByClick')
const onClick = () => {
  emitCloseDropdown()
  isConfigAutoSticky.value && toStickyByClick()
  const sort = props.value == props.ascValue ? props.descValue : props.ascValue
  analysisClickSort({
    sort,
    sortType: props.label,
    clickType: getClickType(unrefs({ sort, sortMap, topTwoList })),
  })
  emits('change', { params: { sort } })
}
</script>

<style scoped lang="less">
.nav-bar {
  &__price {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 3.36rem;
  }

  &__label {
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }

  &__label-active {
    font-weight: 700;
    color: #222;
    font-weight: 600;
    color: var(@sui_color_gray_dark1, #000);
  }

  &__icon {
    width: 9px;
    height: 16px;
    display: inline-block;
    position: relative;

    >span {
      position: absolute;
    }

  }
  
  &__icon-asc {
    top: -3px
  }

  &__icon-desc {
    bottom: -3px
  }
}

</style>
