<template>
  <div class="sort-quick__container">
    <swiper-container 
      ref="swiperEl"
      class="swiper__container"
      init="false"
      destroy-on-disconnected="false"
    >
      <swiper-slide
        v-for="item in sortQuickList"
        :key="item.value"
        class="swiper__slide"
      >
        <NavBarButton
          :icon="item.icon"
          :label="item.label"
          :active="item.active"
          :value="item.value"
          @change="onChange"
          @toStickyByClick="onToStickyByClick"
        />
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
export default {
  name: 'SortQuick',
}
</script>

<script setup>
import { ref, computed, inject, onMounted } from 'vue'

import NavBarButton from '../NavBarItem/NavBarButton.vue'

import { register } from 'swiper/element'
typeof window !== 'undefined' && register()

const sortMap2 = inject('sortMap2', [])
const queryParams = inject('queryParams', [])

const swiperEl = ref() // swiper容器 // 
const swiperInstance = ref(null) // swiper实例

const sortQuickList = computed(() => {
  return sortMap2.value.map(item => ({
    value: item.value,
    label: item.label,
    active: queryParams.value?.sort == item.value,
    icon: item.label_en == 'Price Low To High' ? 'icon-price_px_' : item.label_en == 'Price High To Low' ? 'icon-price_px_-' : ''
  }))

})

const swiperInitialize = () => { 
  const swiperParams = {
    slidesPerView: 'auto',
    freeMode: true,
  }

  Object.assign(swiperEl.value, swiperParams)
  swiperEl.value.initialize()
  swiperInstance.value = swiperEl.value.swiper
}

onMounted(async () => {
  swiperInitialize()
})

const emits = defineEmits(['change', 'toStickyByClick'])
const onChange = params => emits('change', params)
const onToStickyByClick = () => emits('toStickyByClick')
</script>

<style scoped lang="less">
.sort-quick {
  &__container {
    height: 1.17rem;
    background: #fff;
    display: flex;
  }
}


.swiper {
  &__container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  &__slide {
    width: auto;
    margin-right: 8px;
    flex-shrink: 0;

    display: flex;
  }
}
</style>
