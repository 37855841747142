import { ref, watch } from 'vue'
import { TAG_ITEM_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'
import { getKidValue } from 'public/src/pages/components/FilterBar/utils/kids.js'

function getKidsTags ({ filterBar, queryParams, language }) {
  const { kidsTag } = filterBar.filterData || {}
  if (!kidsTag) return []

  let selectedKid = null
  const { child_list = [] } = kidsTag
  const { kid_id, tag_ids } = queryParams
  child_list.forEach(item => {
    item.value = getKidValue(item)
    if (kid_id == item.child_id && tag_ids == item.value) {
      item.active = true
      selectedKid = item
    }
  })
  // 排序kid
  child_list?.[0]?.last_update_time && child_list.sort((a, b) => new Date(b.last_update_time).getTime() - new Date(a.last_update_time).getTime())

  return [{
    child_list,
    value: 'kids',
    curValue: selectedKid?.child_id || '',
    selected: Boolean(selectedKid),
    tagItemType: TAG_ITEM_TYPE.KIDS,
    label: selectedKid?.nickname ?? language.SHEIN_KEY_PWA_32761
  }]
}

export function useKidTags ({ filterBar, language, queryParams }) {
  const list = ref(getKidsTags(unrefs({ filterBar, language, queryParams })))

  watch(filterBar, () => { 
    list.value = getKidsTags(unrefs({ filterBar, language, queryParams }))
  })

  return list
}
