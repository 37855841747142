<template>
  <div class="nav-bar sort-export__container">
    <div class="sort-export__sort">
      <swiper-container 
        ref="swiperEl"
        class="swiper__container"
        init="false"
        destroy-on-disconnected="false"
      >
        <swiper-slide
          class="swiper__slide"
        >     
          <NavBarSort
            @change="onChange"
            @visibleChange="onVisibleChange"
            @toStickyByClick="onToStickyByClick"
          />
        </swiper-slide>

        <swiper-slide
          :class="{
            'swiper__slide': true,
            'swiper__slide-two': true,
            'swiper__slide-evenly': popularItem && priceItem
          }"
        >
          <NavBarButton
            v-if="popularItem"
            :label="popularItem.label"
            :active="popularItem.active"
            :value="popularItem.value"
            @change="onChange"
            @toStickyByClick="onToStickyByClick"
            @hook:mounted="swiperInitialize"
          />

          <NavBarPrice
            v-if="priceItem"
            :label="priceItem.label"
            :value="priceItem.value"
            :asc-value="priceItem.ascValue"
            :desc-value="priceItem.descValue"
            @change="onChange"
            @toStickyByClick="onToStickyByClick"
            @hook:mounted="swiperInitialize"
          />
        </swiper-slide>
      </swiper-container>
    </div>

    <div class="sort-export__filter">
      <NavBarFilter
        ref="navBarFilter"
        :menu-config="menuConfig"
        :filter-num="filterNum"
        @change="onChange"
        @saveCurrAttr="onSaveCurrAttr"
        @visibleChange="onVisibleChange"
        @toStickyByClick="onToStickyByClick"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SortExport',
}
</script>

<script setup>
import { ref, unref, watch, computed, inject, provide, onMounted, onBeforeUnmount, defineExpose, nextTick } from 'vue'
import { register } from 'swiper/element'
import { runDelay } from 'public/src/js/utils/index.js'
import { throttle } from '@shein/common-function'

// components
import NavBarSort from '../NavBarItem/NavBarSort.vue'
import NavBarFilter from '../NavBarItem/NavBarFilter.vue'

import { NAV_BAR_ONE_TWO_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'
import { useTopTwoList } from 'public/src/pages/components/FilterBar/NavBar/hooks/useSort.js'
import { useMenuConfig, useMenuFilterNum } from 'public/src/pages/components/FilterBar/NavBar/hooks/useMenuConfig.js'

import { analysisExposeSort } from 'public/src/pages/components/FilterBar/NavBar/utils/analysis.js'

import NavBarPrice from '../NavBarItem/NavBarPrice.vue'
import NavBarButton from '../NavBarItem/NavBarButton.vue'

// const NavBarPrice = defineAsyncComponent({ loader: () => import('../NavBarItem/NavBarPrice.vue'), delay: 0 })
// const NavBarButton = defineAsyncComponent({ loader: () => import('../NavBarItem/NavBarButton.vue'), delay: 0 })

typeof window !== 'undefined' && register()

const locals = inject('locals', [])
const catInfo = inject('catInfo', [])
const sortMap = inject('sortMap', [])
const filterBar = inject('filterBar', {})
const language = inject('language', {})
const sortAllList = inject('sortAllList', [])
const listAbtResult = inject('listAbtResult', [])
const queryParams = inject('queryParams', {})

const [menuConfig, updateMenuConfig]  = useMenuConfig({ locals, catInfo, filterBar, queryParams, language, listAbtResult })
const filterNum = useMenuFilterNum(menuConfig)

const swiperEl = ref() // swiper容器 // 
const swiperInstance = ref(null) // swiper实例

const topTwoList = useTopTwoList({ filterBar, language, sortAllList, queryParams })
const popularItem = computed(() => topTwoList.value.find(item => item.oneTwoType === NAV_BAR_ONE_TWO_TYPE.BUTTON))
const priceItem = computed(() => topTwoList.value.find(item => item.oneTwoType === NAV_BAR_ONE_TWO_TYPE.PRICE))
provide('topTwoList', topTwoList)

const mountedNum = ref(0)
const mountedTotal = computed(() => {
  let total = 1
  popularItem.value && total++
  priceItem.value && total++

  return total
})
const swiperInitialize = () => { 
  mountedNum.value++
  if (mountedNum.value < mountedTotal.value) return
  const swiperParams = {
    slidesPerView: 'auto',
    freeMode: true,
    slideToClickedSlide: true,
    setWrapperSize: true,
  }

  Object.assign(swiperEl.value, swiperParams)
  swiperEl.value.initialize()
  swiperInstance.value = swiperEl.value.swiper
}

const navBarFilter = ref()
const openSideFilter = () => { 
  navBarFilter.value?.openSideFilter()
}
// 更新数据，用于首屏完后更新数据
const updateCateData = (data) => {
  updateMenuConfig(data)
}

const onResizeCallback = throttle({
  func: async () =>  {
    if (swiperInstance.value) {
      swiperInstance.value.wrapperEl.removeAttribute('style')
      await nextTick()
      swiperInstance.value.update()
    }
  },
  wait: 100,
})
defineExpose({ openSideFilter, updateCateData })

onMounted(() => {
  runDelay(swiperInitialize)
  window.addEventListener('resize', onResizeCallback)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResizeCallback)
})

watch(filterBar, () => {
  filterBar.value?.filterConfig?.isExportFilter
    && analysisExposeSort({
      sortMap: unref(sortMap),
      topTwoList: unref(topTwoList),
      queryParams: unref(queryParams)
    })
}, { immediate: true })

const emits = defineEmits(['change', 'toStickyByClick'])
const onChange = params => emits('change', params)
const onToStickyByClick = () => emits('toStickyByClick')
const onSaveCurrAttr = (id, type) => emits('saveCurrAttr', id, type)
const onVisibleChange = flag => emits('visibleChange', flag)
</script>

<style scoped lang="less">
.sort-export {
    &__container {
      height: 1.17rem;
      background: #fff;
      display: flex;
    }

    &__sort {
      flex: 1;
      .flexbox();
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      overflow: hidden;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 10px;
        width: 1px;
        height: 24px;
        z-index: 2;
        background: #e5e5e5;
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        width: 0.5334rem;
        height: 100%;
        background: linear-gradient(to left, #fff 0, #fff 0.32rem, rgba(255, 255, 255, 0) 0.5334rem);
        z-index: 1;
      }
    }

    &__filter {
      padding: 0 12px;
    }
}

.swiper {
  &__container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  &__slide {
    width: auto;
    margin-right: 8px;
    flex-shrink: 0;
    display: flex;
  }

  &__slide-two {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  &__slide-evenly {
    justify-content: space-evenly;
  }
}
</style>

<style lang="less">
.nav-bar__price {
  padding-right: 0.5334rem;
}
</style>
