
import { ref, watch } from 'vue'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'
import { getCateList } from 'public/src/pages/components/FilterBar/utils/category.js'
import { FILTER_BAR_MAP } from 'public/src/pages/components/FilterBar/utils/const.js'

export function useCateNavBar ({ catInfo, filterBar, language, listAbtResult, queryParams }) { 
  const list = ref([])

  const updateCateNavBar = () =>  {
    list.value = filterBar.value?.filterConfig?.isExportFilter
      ? []
      : getCateList(unrefs({
        catInfo,
        filterBar,
        listAbtResult,
        queryParams,
        language,
        filterBarType: FILTER_BAR_MAP.NAV_BAR
      }))
  }
  watch(filterBar, () => { updateCateNavBar() }, { immediate: true })

  return [list, updateCateNavBar]
}

export function useCateTags ({ catInfo, filterBar, language, listAbtResult, queryParams }) {
  const list = ref([])

  const updateUseCateTags = () => {
    list.value = !filterBar.value?.filterConfig?.isExportFilter
      ? []
      : getCateList(unrefs({
        catInfo,
        filterBar,
        listAbtResult,
        queryParams,
        language,
        filterBarType: FILTER_BAR_MAP.CLOUD_TAGS
      }))
  }

  watch(filterBar, () => { updateUseCateTags() }, { immediate: true })
  return [list, updateUseCateTags]
}
