import { ref, watch } from 'vue'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'
import { getCustomList } from 'public/src/pages/components/FilterBar/utils/custom.js'
import { FILTER_BAR_MAP } from 'public/src/pages/components/FilterBar/utils/const.js'

export function useCustomNavBar ({ filterBar, queryParams }) { 
  const list = ref([])

  watch(filterBar, () => {
    list.value = filterBar.value?.filterConfig?.isExportFilter
      ? []
      : getCustomList(unrefs({ filterBar, queryParams, filterBarType: FILTER_BAR_MAP.NAV_BAR }))
  }, { immediate: true })

  return list
}

export function useCustomTags ({ filterBar, queryParams }) {
  const list = ref([])
  
  watch(filterBar, () => {
    list.value = !filterBar.value?.filterConfig?.isExportFilter
      ? []
      : getCustomList(unrefs({ filterBar, queryParams, filterBarType: FILTER_BAR_MAP.CLOUD_TAGS }))
  }, { immediate: true })

  return list
}

