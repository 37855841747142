
import { computed, ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { throttle } from '@shein/common-function'

import { PIC_TOP_NAV_LAYOUT } from 'public/src/pages/components/FilterBar/utils/const.js'
import { PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'
import { emitCloseDropdown } from 'public/src/pages/components/FilterBar/eventCenter/index.js'

export function usePicTopNavLayout ({ catInfo, list, hasSearchBannerCard, hasCorrectionWord }) {
  return computed(() => {
    if (catInfo.value.type === 'picks') return PIC_TOP_NAV_LAYOUT.PICKS
    if (list.value?.length > 1) {
      if (catInfo.value.type === 'search' && !hasSearchBannerCard && !hasCorrectionWord) {
        return PIC_TOP_NAV_LAYOUT.CARD
      } else {
        return PIC_TOP_NAV_LAYOUT.TABS 
      }
    }
    if (list.value?.[0]?.is_blank) return PIC_TOP_NAV_LAYOUT.BLANK
    
    return PIC_TOP_NAV_LAYOUT.LIST
  })
}

export function useClassName ({ prefix, shape, isSmallStatus, isActive, isConfigActive = true, extra = {} }) {
  return computed(() => {
    const isActiveStatus = isConfigActive.value
      ? (isActive?.value || false)
      : false
    const isCircle = shape.value === PIC_TOP_NAV_SHAPE.CIRCLE

    return {
      ...extra,
      [prefix]: true,
      [`${prefix}__small`]: isSmallStatus.value,
      [`${prefix}__active`]: isActiveStatus,
      [`${prefix}__rect`]: !isCircle && !isSmallStatus.value,
      [`${prefix}__rect-small`]: !isCircle && isSmallStatus.value,
      [`${prefix}__circle`]: isCircle && !isSmallStatus.value,
      [`${prefix}__circle-small`]: isCircle && isSmallStatus.value
    }
  })
}

export const useSlideRow = ({ container, cssRight, onSlideLeft, onSlideRight }) => {

  const startX = ref(0)
  const startTime = ref(0)
  const disableMove = ref(false)

  const handleTouchStart = (event) => {
    startX.value = event.touches[0].clientX // 记录触摸开始位置
    startTime.value = new Date().getTime() // 记录触摸开始时间
  }

  const handleTouchMove = throttle({
    func: (event) => {
      event.preventDefault() // 防止页面上下抖动
      if (disableMove.value) {
        return
      }
      emitCloseDropdown()
      const moveX = event.touches[0].clientX // 获取触摸移动位置
      const distanceX = moveX - startX.value // 计算移动距离
      const endTime = new Date().getTime() // 获取触摸结束时间
      const duration = endTime - startTime.value
  
      // 判断是向左还是向右滑动
      if (distanceX > 20 && duration < 2000) {
        cssRight ? onSlideLeft() : onSlideRight()
        disableMove.value = true
      } else if (distanceX < -20 && duration < 2000) {
        cssRight ? onSlideRight() : onSlideLeft()
        disableMove.value = true
      }
    },
    wait: 50,
    options: {
      trailing: false,
      leading: true,
    }
  })

  const handleTouchEnd = () => {
    disableMove.value = false
  }

  onMounted(() => {
    const $container = container.value
    $container.addEventListener('touchstart', handleTouchStart)
    $container.addEventListener('touchmove', handleTouchMove)
    $container.addEventListener('touchend', handleTouchEnd)
  })

  onBeforeUnmount(() => {
    const $container = container.value
    $container.removeEventListener('touchstart', handleTouchStart)
    $container.removeEventListener('touchmove', handleTouchMove)
    $container.removeEventListener('touchend', handleTouchEnd)
  })
}

export const useSlideActiveIndex = (props) => {
  const activeIndex = ref(0)

  const setActiveIndex = (val) => {
    activeIndex.value = val
  }

  // watch(() => props.list, (newVal, oldVal) => {
  //   // TODO 待排查 为什么会产生两个字段完全一样的对象 但指针不一样
  //   if (isEqual(newVal, oldVal)) {
  //     return
  //   }
  //   activeIndex.value = 0
  // })
  // ! list来自于getFormatList的map生成 每一次执行地址都不一样 所以会触发watch
  watch(() => props.originList, () => {
    activeIndex.value = 0
  })

  return {
    activeIndex,
    setActiveIndex,
  }
}
